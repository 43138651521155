import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/carte',
    name: 'Carte',

    component: () => import( '../views/Carto.vue')
  },
  {
    path: '/sentinelles',
    name: 'Données par sentinelle',
    component: () => import('../views/Sites.vue')
  },
  {
    path: '/sites',
    name: 'Données par site',
    component: () => import( '../views/Sentinelles.vue')
  },
  {
    path: '/infos-API',
    name: "Informations sur L'API",
    component: () => import('../views/Infos_API.vue')
  },
  {
    path: '/indicateur-globale',
    name: "Indicateur globale",
    component: () => import('../views/Global_indicator.vue')
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
