<div class="relative bg-white py-6 px-6 rounded-3xl w-64 h-100 my-4 shadow-xl">
  <div class=" text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl left-4 -top-6"
    :style="getColor(cardData.color)">
    <!-- svg  -->
    <img :src="getImgUrl(cardData.img_path)" class="h-10" />



  </div>
  <div class="mt-8 flex flex-col justify-items-stretch ">
    <p class="text-xl font-semibold my-2">{{cardData.title}}</p>
    <div class="flex space-x-2 text-gray-400 text-sm">
      <!-- svg  -->

      <p>{{text}}</p>
    </div>

    <div class="border-t-2 my-4"></div>

    <div class="flex justify-between">

      <div class="my-2">

        <router-link tag="button" class=" text-white font-semibold py-2 px-4 rounded opacity-100 "
          :style="getColor(cardData.color)" :to="cardData.router_link"> Accéder</router-link>



      </div>

    </div>
  </div>
</div>