import { String } from "core-js"

export default {
  name: 'homeCard',
  components: {},
  props: {
    cardDataTop:{},
    
    title:{
      type:String,
    },
    text:{
      type:String,
    },
    button_text:{
      type:String,
    },
    img_path:{
      type:String,
    }
  },
  data () {
    return {
      cardData: this.cardDataTop,
      img_url : this.cardDataTop.img_path
      
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    getImgUrl(img_name){
      console.log(img_name)
     return require('@/assets/img/'+img_name)      
    },
    getColor(color){
      return {backgroundColor:color}
    }

  }
}


