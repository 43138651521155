<template>
  <div class="home bg-gray-50 py-8">
    <div class="flex justify-center px-10">
      <h1 class="text-4xl mb-6">
        Application de visualisation des données Econect
      </h1>
    </div>

    <div class="font-medium flex justify-center mb-10 px-10">
      <p>
        Cette application met à disposition un ensemble  d'outils pour visualiser les données de
        capteurs environnementaux installés dans toute l'Occitanie dans le cadre
        du projet Econect
      </p>
      
    </div>
    <div class="flex items-center justify-center">
      <div
        class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4"
      >
        <homeCard
          v-for="card in cards"
          v-bind:key="card.index"
          :cardDataTop="card"
          :text="card.text"
        ></homeCard>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import homeCard from "@/components/home-card/index.vue";

export default {
  name: "Home",
  data: function () {
    return {
      cards: [
        {
          id: 1,
          title: "Carte",
          text: "Localisation des sentinelles",
          button_text: "Accéder",
          img_path: "map.svg",
          color: "#10b981",
          router_link: "/carte",
        },
        {
          id: 2,
          title: "Sentinelles",
          text: "Données par type de sentinelle",
          button_text: "Accéder",
          img_path: "zoom_len.svg",
          color: "#3b83f6",
          router_link: "/sentinelles",
        },
        {
          id: 3,
          title: "Sites",
          text: "Données par site",
          button_text: "Accéder",
          img_path: "placeholder.svg",
          color: "#f59e0b",
          router_link: "/sites",
        },
        {
          id: 6,
          title: "Analyse transversale",
          text: "Indicateur multimétriques",
          button_text: "Accéder",
          img_path: "global.svg",
          color: "#ff70a6",
          router_link: "/indicateur-globale",
        },
        {
          id: 4,
          title: "API",
          text: "Informations sur l'utilisation de l'API Econect",
          button_text: "Accéder",
          img_path: "api.svg",
          color: "#D8D8D8",
          router_link: "/infos-API",
        },
        {
          id: 5,
          title: "A propos",
          text: "Informations sur le projet Econect",
          button_text: "Accéder",
          img_path: "question.svg",
          color: "#D8D8D8",
          router_link: "/about",
        },
      ],
    };
  },
  components: {
    homeCard,
  },
};
</script>
